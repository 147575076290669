import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import './react-datepicker.css';
import { enUS, es, eu } from 'date-fns/locale'; // Import needed locales

const locales = {
  en: enUS,
  es: es,
  eu: eu,
};

const DatePickerComponent = ({ intl, ...props }) => {
  const [selectedDate, setSelectedDate] = useState(props.selected ? props.selected : null);
  const locale = locales[intl?.locale] || enUS;

  return (
    <DatePicker
      {...props}
      selected={selectedDate}
      onChange={date => {
        setSelectedDate(date);
        if (typeof props.onChange === 'function') {
          props.onChange(date);
        }
      }}
      dateFormat="dd-MM-yyyy"
      placeholderText="dd-MM-yyyy"
      locale={locale} // Dynamically set locale
    />
  );
};

export default DatePickerComponent;
